var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("teaching.mattercategory", false, "Categoría"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'teaching.mattercategory',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('teaching.add_mattercategory'),expression:"'teaching.add_mattercategory'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'teaching.mattercategory',
            false,
            'Categoría'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-matter_category-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.matterCategories,"pagination":_vm.matterCategories.length,"fields":_vm.matter_categories_fields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(row.item.description)}})]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'teaching.mattercategory',
            false,
            'Categoría'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-matter_category-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{attrs:{"click_button":() => _vm.askForDeleteMatterCategory(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'teaching.mattercategory',
            false,
            'Categoría'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-matter_category-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'teaching.mattercategory',
            false,
            'Categoría'
          )}`,"size":"md","hide-footer":""}},[_c('MatterCategoryForm',{attrs:{"MatterCategory":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedMatterCategory}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-matter_category-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'teaching.mattercategory',
      false,
      'Categoría'
    )}`,"size":"md"}},[_c('MatterCategoryForm',{on:{"created":_vm.slotCreatedMatterCategory}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }