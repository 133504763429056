<template>
  <div>
    <div class="mx-2">
      <h3 class="mt-5">
        {{ $getVisibleNames("teaching.mattercategory", false, "Categoría") }}
        <AlternativeNameButton
          :table_name="'teaching.mattercategory'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <ButtonMedium
            v-can="'teaching.add_mattercategory'"
            v-if="allows_crud"
            :tooltip_text="`Agregar ${$getVisibleNames(
              'teaching.mattercategory',
              false,
              'Categoría'
            )}`"
            :text_button="`Agregar`"
            :click_button="() => $bvModal.show(`new-matter_category-modal`)"
            :icon="'plus'"
            :variant="'primary'"
          >
          </ButtonMedium>
          <!-- <button
            v-can="'teaching.add_mattercategory'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-matter_category-modal`)"
          >
            Agregar
            {{
              $getVisibleNames("teaching.mattercategory", false, "Categoría")
            }}
          </button> -->
        </div>
      </div>
      <GenericBTable
        :items="matterCategories"
        :pagination="matterCategories.length"
        :fields="matter_categories_fields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
      >
        <template #cell(description)="row">
          <div class="rich-text-content" v-html="row.item.description"></div>
        </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <ButtonSmall
            :tooltip_text="`Editar ${$getVisibleNames(
              'teaching.mattercategory',
              false,
              'Categoría'
            )}`"
            :click_button="
              () => $bvModal.show(`edit-matter_category-modal-${row.item.id}`)
            "
            :class="``"
            :icon="'square'"
          ></ButtonSmall>
          <!-- <button-edit
            @click="$bvModal.show(`edit-matter_category-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'teaching.mattercategory',
                false,
                'Categoría'
              )}`
            "
          ></button-edit> -->
          <ButtonSmall
            :click_button="() => askForDeleteMatterCategory(row.item.id)"
            :tooltip_text="`Eliminar ${$getVisibleNames(
              'teaching.mattercategory',
              false,
              'Categoría'
            )}`"
            :icon="'trash'"
          ></ButtonSmall>
          <!-- <button-delete
            @click="askForDeleteMatterCategory(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'teaching.mattercategory',
                false,
                'Categoría'
              )}`
            "
          ></button-delete> -->
          <b-modal
            :id="`edit-matter_category-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'teaching.mattercategory',
              false,
              'Categoría'
            )}`"
            size="md"
            hide-footer
          >
            <MatterCategoryForm
              :MatterCategory="row.item"
              @updated="slotUpdatedMatterCategory"
              :show_title="false"
            ></MatterCategoryForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-matter_category-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'teaching.mattercategory',
        false,
        'Categoría'
      )}`"
      size="md"
    >
      <MatterCategoryForm
        @created="slotCreatedMatterCategory"
      ></MatterCategoryForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "MatterCategoryView",
  components: {
    MatterCategoryForm: () =>
      import(
        "@/components/teaching/CurricularResources/MatterCategory/MatterCategoryForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      matterCategories: names.MATTER_CATEGORIES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "teaching.add_mattercategory"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    matter_categories_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames("teaching.mattercategory", true, "Categorías")
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          // sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    askForDeleteMatterCategory(matter_category_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.mattercategory",
          false,
          "Categoría"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_MATTER_CATEGORY, matter_category_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "teaching.mattercategory",
                    false,
                    "Categoría"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedMatterCategory() {
      this.$bvModal.hide("new-matter_category-modal");
    },
    slotUpdatedMatterCategory(matter_category) {
      this.$bvModal.hide(`edit-matter_category-modal-${matter_category.id}`);
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_MATTER_CATEGORIES);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-matter_category {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>